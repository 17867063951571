.common-form-control {
  font-family: var(--bs-family-Jost-Medium);
  font-size: $fs-14px !important;
  color: $black !important;
  border-radius: $fs-2px !important;
  border-color: $dim-grey !important;
  background-color: $dim-grey !important;
  height: $fs-32px !important;
  @include setFont(
    500 !important,
    normal !important,
    normal !important,
    normal !important,
    left !important
  );
  &:focus {
    color: $black !important;
    box-shadow: none !important;
    border-color: $dim-grey !important;
  }
  &::placeholder {
    opacity: 0.45 !important;
    font-size: $fs-12px !important;
  }
  @include mobileScreen {
    height: $fs-54px !important;
    font-size: $fs-24px;
  }
  @include mobileScreen {
    height: $fs-38px !important;
  }
}

.common-label {
  font-size: $fs-13px;
  font-family: $jostLight;
  color: $dark-grey;
  margin-bottom: 0 !important;
  @include mobileScreen {
    font-size: $fs-16px;
  }
}

.common-labe-small {
  font-family: $jostLight;
  font-size: $fs-12px;
  color: $dim-grey;
}

.text-position {
  text-align: right;
  @include mobileScreen {
    text-align: left;
  }
}

textarea.form-control {
  @include mobileScreen {
    height: 6rem !important;
  }
  height: 4rem !important;
}

textarea.textarea-form-control {
  height: 10rem !important;
}

.has-error {
  .form-control {
    border-color: $danger !important;
  }
}

.help-block {
  color: $danger;
  margin-bottom: 0 !important;
  padding-top: $fs-4px;
}
