

@import '../stylesheets/app';

body{
  margin: 0;
}

// .header-class{
//   height: 60vh;
//   position: relative;
//   width: 100%;
//   overflow: hidden;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 50px;
// }

// .header-class::before {
//   content: '';
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   width: 100vh;
//   height: 100vh;
//   background: #2364C2;
//   border-radius: 42%;
//   transform-origin: bottom;
//   transform: translateX(-50%) scale(4);
// }

// .footer-class{
//   background-color: #1C2040;
//   height: 20vh;
// }
// .content-class{
//   height: 100vh;

// }
// .content{
//   position: relative;
//   z-index: 1;
//   max-width: 1000px;
//   text-align: center;
//   color: #ffff;
//  }
//  .content h2 {
//    font-size: 2em;
//  }
//  .content p {
//    font-size: 1em;
//  }

//  .border-class-foot {
//   height: 1.6vh;
//  }
// .border-class {
//   height: 1.6vh;
//   position: fixed;
//   top: 0;
//   width: 100%;
//   z-index: 1;
// }
// .circle {
//   height: 20px;
//   width: 20px;
//   border-radius: 50%;
//   margin-left: 4px;
// }

// .color-line{
//   height: 1.6vh;
//   width: 11.1%;
// }
// .content-header{
//   color:royalblue;
//   font-size: 1.5rem;
//   text-align: center;
//   padding: 40px;
// }

// .div-1{
//   background-color: #E6350F;
// }
// .div-2{
//   background-color: #C02B0A;
// }
// .div-3{
//   background-color: #F18057;
// }
// .div-4{
//   background-color: #4DB9BD;
// }
// .div-5{
//   background-color: #66EBE9;
// }
// .div-6{
//   background-color: #38B6FF;
// }
// .div-7{
//   background-color: #4C96E9;
// }
// .div-8{
//   background-color: #032A90;
// }
// .div-9{
//   background-color: #061B65;
// }
