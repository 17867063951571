@font-face {
  font-family: "Jost-Light";
  src: url("./fonts/Jost-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Jost-Medium";
  src: url("./fonts/Jost-Medium.ttf") format("opentype");
}

@font-face {
    font-family: "Jost-Regular";
    src: url("./fonts/Jost-Regular.ttf") format("opentype");
  }

@font-face {
  font-family: "NunitoSans-Italic";
  src: url("./fonts/NunitoSans-Italic.ttf") format("opentype");
}

@font-face {
    font-family: "NunitoSans-Light";
    src: url("./fonts/NunitoSans-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Jost-Bold";
  src: url("./fonts/Jost-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "NunitoSans-SemiBold";
  src: url("./fonts/NunitoSans-Light.ttf") format("opentype");
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: url("./fonts/NunitoSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "NunitoSans-Black";
  src: url("./fonts/NunitoSans-Black.ttf") format("opentype");
}

@font-face {
  font-family: "NunitoSans-Bold";
  src: url("./fonts/NunitoSans-Bold.ttf") format("opentype");
}

  $jostRegular: Jost-Regular;
  $jostLight: Jost-Light;
  $jostMedium: Jost-Medium;
  $nunitosansItalic: NunitoSans-Italic;
  $nunitosansLight: NunitoSans-Light;
  $nunitoSans-SemiBold: NunitoSans-SemiBold;
  $nunitoSans-Regular: NunitoSans-Regular;
  $nunitoSans-Black: NunitoSans-Black;
  $nunitoSans-Bold: NunitoSans-Bold;
  $jostBold: Jost-Bold;

$fs-1px: 0.06rem; //	1px
$fs-2px: 0.125rem; //	2px
$fs-3px: 0.187rem; //	3px
$fs-4px: 0.25rem; //	4px
$fs-5px: 0.312rem; //	5px
$fs-6px: 0.375rem; //	6px
$fs-7px: 0.437rem; //	7px
$fs-8px: 0.5rem; //	8px
$fs-9px: 0.55rem; //	9px
$fs-10px: 0.625rem; //	10px
$fs-11px: 0.7rem; //	11px
$fs-11-68px: 0.73rem; // 11.68px
$fs-12px: 0.75rem; //	12px
$fs-13px: 0.8rem; //	13px
$fs-13-28px: 0.83rem; // 13.28px
$fs-14px: 0.875rem; //	14px
$fs-15px: 0.95rem; //	15px
$fs-16px: 1rem; //	16px
$fs-17px: 1.05rem; //	17px
$fs-18px: 1.125rem; //	18px
$fs-19px: 1.2rem; //	19px
$fs-20px: 1.25rem; //	20px
$fs-21px: 1.3rem; //	21px
$fs-22px: 1.4rem; //	22px
$fs-23px: 1.45rem; //	23px
$fs-24px: 1.5rem; //	24px
$fs-25px: 1.5625rem; //25px
$fs-26px: 1.6rem; //	26px
$fs-27px: 1.68rem; //	27px
$fs-28px: 1.75rem; //	28px
$fs-29px: 1.8rem; //	29px
$fs-30px: 1.875rem; //30px
$fs-32px: 2rem; //	32px
$fs-34px: 2.125rem; //34px
$fs-33px: 2.0625rem; //33px
$fs-35px: 2.2rem; //	35px
$fs-36px: 2.25rem; //	36px
$fs-37px: 2.3rem; //	37px
$fs-38px: 2.35rem; //	38px
$fs-40px: 2.45rem; //	40px
$fs-42px: 2.55rem; //	42px
$fs-43px: 2.602rem; //43px
$fs-44px: 2.75rem; //	44px
$fs-45px: 2.8125rem; // 45px
$fs-48px: 3rem; //	48px
$fs-50px: 3.125rem; //50px
$fs-54px: 3.375rem; //54px
$fs-56px: 3.5rem; //56px
$fs-60px: 3.76rem; //  60px
$fs-63px: 3.9375rem; //63px
$fs-64px: 4rem; //64px
$fs-67px: 4.18rem; //  67px
$fs-68px: 4.25rem; //68px
$fs-72px: 4.5rem; //  72px
$fs-75px: 4.7rem; //74px
$fs-80px: 5rem; //80px
$fs-88px: 5.5rem; //88px
$fs-90px: 5.625rem; //90px
$fs-96px: 6rem; //96px
$fs-104px: 6.5rem; //104px
$fs-108px: 6.75rem; //108px
$fs-112px: 7rem; //112px
$fs-120px: 7.5rem; //116px
$fs-128px: 8rem; //128px
$fs-144px: 9rem; //144px
$fs-160px: 10rem; //160px
$fs-176px: 11rem; //176px
$fs-192px: 12rem; //192px
$fs-208px: 13rem; //208px
$fs-216px: 13.5rem; //216px
$fs-224px: 14rem; //216px
$fs-240px: 15rem; //240px
$fs-260px: 16.5rem; //260px
$fs-280px: 17.5rem; //280px
$fs-304px: 20rem; //304px
$fs-336px: 21rem; //320px
$fs-352px: 22rem; //352px
$fs-384px: 24rem; //384px
$fs-408px: 25.5rem;
$fs-432px: 27rem; //432px
$fs-448px: 28rem; //448px
$fs-496px: 31rem; //496px
$fs-528px: 33rem; //528px
$fs-560px: 35rem; //560px
$fs-600px: 41rem; //560px
$fs-650px: 44.5rem; //560px
$fs-700px: 54rem; //592px
$fs-900px: 75rem; //592px
