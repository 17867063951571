
$red: #E50000;
$speech-red: #C02900;
$crusta-orange: #F78058;
$white: #FFFFFF;
$black: #000000;
$transparent: transparent;
$iris-blue: #00B8BD;
$light-iris-blue: #00EBE9;
$dodger-blue: #23B6FF;
$cornflower-blue: #4C96E9;
$smalt-blue: #002690;
$navy-blue: #000C65;
$light-blue:#779AFC;
$navy-blue-medium: #1C2040;
$blue: #1359BE;
$dark-grey: #292929;
$grey: #6B6B6B;
$danger: #dc3545;
$warning: #e88a02;
$gainsboro-white: #D8D8D8;
$dim-grey: #F7F7F8;
$white-smoke: #F4FBFF;
$theme-blue:#2364C2;
$nero-grey: #292929;
$charcoal-grey: #4D4D4D;
$dark-light-grey: #707070;
$madison-blue: #2c3e50;
$solitude-blue: #F5F8FA;
$white-smoke-light: #F7F7F7;
$white-smoke-medium: #FAFAFA;
$medium-pink: #fd4b9a;
$medium-slate-blue: #645be8;
$macaroni-orange: #FEBB86;
$razzle-rose: #F44CE1;
$pattens-blue:#D7ECFA;
$dark-pink: #e76293;
$madison-blue: #2c3e50;
$white: #ffffff;
$black: #000000;
$nero-grey: #292929;
$transparent: "transparent";
$dark-pink: #e76293;
$medium-slate-blue: #645be8;
$medium-pink: #fd4b9a;
$danger: #dc3545;
$warning: #e88a02;
$grey: #dedede;
$grey-light: #dedede;
$blue: #408BF9;
$kelly-green: #2FB40E;
$white-smoke: #dfdfdf;
$medium-slate-blue: #7C59E7;
$macaroni-orange: #FEBB86;
$solitude-blue: #F5F8FA;
$razzle-rose: #F44CE1;
$gainsboro-white: #D8D8D8;
$charcoal-grey: #4D4D4D;
$light-blue: #765AE8;
$dark-grey: #888888;
$dark-light-grey: #707070;
$white-smoke-light: #F7F7F7;
$pattens-blue:#D7ECFA;
$Matterhorn:#575656;
$white-smoke-medium: #FAFAFA;
$crimson-red: #DC0D45;
$black-pearl: #050708;
$navy-blue-medium: #1C2040;
$jostLight: Jost-Light;
        
