// .section-title {
//   font-family:  $nunitosansLight;
//   color: $nero-grey;
//   @include setFont(500, normal, normal, normal, center);
//   @include setMargin(0, 0, 0, 0);
//   @include setPadding(0, 0, 0, 0);
//   line-height: $fs-28px !important;
// }
// .welcome-header {
//   font-family:  $nunitosansLight;
//   color: #4E4B40;
//   @include setFont(500, normal, normal, normal, center);
//   @include setMargin(0, 0, 0, 0);
//   @include setPadding(0, 0, 0, 0);
//   line-height: $fs-28px !important;
// }
// .sub-section-title {
//   font-family: $nunitoSans-Regular;
//   color: $charcoal-grey;
//   @include setFont(500, normal, normal, normal, left);
//   @include setMargin(0, 0, 0, 0);
//   @include setPadding(0, 0, 0, 0);
//   line-height: $fs-28px !important;
// }
// .banner-padding{
//   padding-top: 5rem !important;
// }
// .title-white {
//   color: $white;
// }
// .title-black {
//   color: $black;
// }
// .title-dim-grey {
//   color: $dim-grey;
// }
// .title-blue {
//   color: $blue;
// }
// .title-charcoal-grey {
//   color: $charcoal-grey;
// }
// .title-dark-light-grey {
//   color: $dark-light-grey;
// }
// .line-height-20px {
//   line-height: $fs-20px !important;
// }
// .line-height-35px {
//   line-height: $fs-35px !important;
// }
// .line-height-54px {
//   line-height: $fs-54px !important;
// }
// .line-height-48px {
//   line-height: $fs-48px !important;
// }
// .nunitoSans-SemiBold {
//   font-family: $nunitoSans-SemiBold !important;
// }
// .nunitoSans-Regular {
//   font-family: $nunitoSans-Regular !important;
// }
// .nunitoSans-Black {
//   font-family: $nunitoSans-Black !important;
// }
// .nunitoSans-Bold {
//   font-family: $nunitoSans-Bold !important;
// }
// .strip-no-padding {
//   @include setPadding(0!important, 0!important, 0!important, 0!important);
// }
// .bg-madison-blue {
//   background-color: $madison-blue;
// }
// .bg-solitude-blue {
//   background-color: $solitude-blue;
// }
// .bg-white-smoke-light {
//   background-color: $white-smoke-light;
// }
// .bg-white-smoke-medium{
//   background-color: $white-smoke-medium;
// }
// .main-header-container {
//   position: fixed;

// }
// .header-container {
//   position: relative;
//   width: 100%;
//   height: 4rem;
//   display: table;
//   align-items:flex-end;
//   background: $white;
//   justify-content: center;
//   z-index: 999;
//   top: 0;
//   left: 0;
//   right: 0;
//   box-shadow: 0px 0px 0px #00000029;
//   @include mobileScreen {
//     align-items: center;
//     justify-content: flex-end;
//     padding-right: 2rem;
//     padding-left: 2rem;
//   }
//   &.mega-menu--hide {
//     position: relative;
//     opacity: 0 !important;
//   }
// }
// .menu-container {
//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
// font-size: 24px;
// }
// .spirelab-img--width {
//   width: 7rem;
// }
// .spire-lab-img--width {
//   object-fit: contain;
//   width: 2.5rem;
// }
// .custom-link--position {
//   font-family:  $nunitosansLight;
//   font-size: $fs-14px;
//   cursor: pointer;
//   text-transform: capitalize;
//   padding-bottom: 0.35rem;
//   .custom-link {
//     @include setPadding(0, $fs-12px, $fs-4px, $fs-10px);
//     color: $nero-grey !important;
//     cursor: pointer;
//     &:hover {
//       color: $medium-pink !important;
//       cursor: pointer;
//       border-bottom: $fs-2px solid $medium-pink !important;
//     }
//     &.custom-link-active {
//       cursor: pointer;
//       font-family: $nunitoSans-SemiBold;
//       font-weight: 600;
//       padding-bottom: 0;
//       border-bottom: $fs-3px solid $medium-pink !important;
//       color: $medium-pink !important;
//     }
//   }
// }
// .custom-link--mobile-position {
//   font-family:  $nunitosansLight;
//   font-size: $fs-18px;
//   text-transform: capitalize;
//   cursor: pointer;
//   .custom-link {
//     width: 100%;
//     display: flex;
//     @include setPadding($fs-16px, $fs-40px, $fs-16px, $fs-32px);
//     color: $nero-grey !important;
//     cursor: pointer;
//     &:hover {
//       color: $medium-pink !important;
//       cursor: pointer;
//       border-bottom: $fs-3px solid $medium-pink !important;
//     }
//     &.custom-link-active {
//       cursor: pointer;
//       font-family: $nunitoSans-SemiBold;
//       font-weight: 600;
//       border-bottom: $fs-3px solid $medium-pink !important;
//       color: $medium-pink !important;
//     }
//   }
// }
// .home-title__container {
//   position: absolute !important;
//   padding-top: 2.5rem;
//   width: 60% !important;
//   margin: auto;
//   left: 0;
//   right: 0;
//   text-align: center;
//   @include mobileScreen {
//     width: 80% !important;
//   }
//   @include desktop-max {
//     width: 60vh !important;
//   }
//   @include hdRes {
//     width: 80% !important;
//   }
// }
// .home-mobile-img--position {
//   @include mobileScreen {
//     height: 40vh;
//   }
//   @include desktop-max {
//     height: 35vh;
//   }
//   height: 60vh;
// }
// .social-icon-width {
//   width: $fs-40px;
//   cursor: pointer;
// }
// .gallery-card-img-container {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   .img-item {
//     width: 100%;
//     height: 300px;
//     object-fit: cover;
//     @include mobileScreen {
//       height: 250px;
//       @include desktop {
//         height: 300px;
//       }
//     }
//   }
// }
// .img-item--position{
//   width: 100%;
//   height: 300px;
//   object-fit: contain;
//   @include mobileScreen {
//     height: 250px;
//     @include desktop {
//       height: 300px;
//     }
//   }
// }
// .about-us-text__container {
//   @include mobileScreen {
//     margin: auto !important;
//   }
// }
// .about-us-description {
//   text-align: left !important;
//   @include mobileScreen {
//     text-align: center !important;
//     padding-top: $fs-32px !important;
//   }
// }
.academics-img-container {
  position: relative;
  // width: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  @include mobileScreen {
    margin: auto;
  }
  .first-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 1;
  }
  .second-circle-container {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  .third-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  &:hover {
    border: none !important;
  }
  &:focus {
    border: none !important;
  }
}

.team-img-container {
  position: relative;
  width: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  @include mobileScreen {
    margin: auto;
  }
  .first-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 1;
  }
  .second-circle-container {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  .third-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 0.2;
  }
}
.academics-people {
  &:nth-child(3n + 1) {
    .first-circle-container {
      border: 15px solid #675BE8;
    }
    .third-circle-container {
      border: 15px solid #675BE8;
    }
  }
  &:nth-child(3n + 2) {
    .first-circle-container {
      border: 15px solid #675BE8;
    }
    .third-circle-container {
      border: 15px solid #675BE8;
    }
  }
  &:nth-child(3n + 3) {
    .first-circle-container {
      border: 15px solid #675BE8;
    }
    .third-circle-container {
      border: 15px solid #675BE8;
    }
  }
}
.team {
  &:nth-child(3n + 1) {
    .first-circle-container {
      border: 1px solid #002690;
    }
    .second-circle-container {
      border: 1px solid #002690;
    }
  }
  &:nth-child(3n + 2) {
    .first-circle-container {
      border: 1px solid #002690;
    }
    .second-circle-container {
      border: 1px solid #002690;
    }
  }
  &:nth-child(3n + 3) {
    .first-circle-container {
      border: 1px solid #002690;
    }
    .second-circle-container {
      border: 1px solid #002690;
    }
  }
}
// .footer-text {
//   text-align: right;
//   @include mobileScreen {
//     font-size: $fs-16px !important;
//     text-align: center;
//   }
// }
// .mobile-footer--position {
//   @include mobileScreen {
//     margin: auto;
//   }
// }
// .mobile-header {
//   position: absolute;
//   left: 0;
//   right: 0;
//   width: 100%;
//   top: 0;
//   min-height: 48vh;
//   background: $white;
//   z-index: 999;
//   padding-top: $fs-18px;
//   padding-left: $fs-24px;
//   padding-right: $fs-24px;
//   box-shadow: 0px 0px 6px #00000029;
// }
// .menu-icon {
//   width: $fs-22px;
//   height: $fs-14px;
//   position: absolute;
//   right: $fs-22px;
//   top: $fs-18px;
// }
// .logo-icon {
//   width: $fs-32px;
//   height: $fs-22px;
//   position: absolute;
//   left: $fs-12px;
//   top: $fs-8px;
// }

// .close-icon {
//   width: 2rem;
//   height: $fs-28px;
//   position: absolute;
//   right: $fs-16px;
//   top: $fs-16px;
// }


// .full-height {
//   height: 100vh;
// }
// .box-shadow {
//   box-shadow: 0 0 $fs-1px 0 rgba(0, 0, 0, 0.16);
//   -webkit-box-shadow: 0 0 $fs-1px 0 rgba(0, 0, 0, 0.75);
//   -moz-box-shadow: 0 0 $fs-1px 0 rgba(0, 0, 0, 0.75);
//   border-radius: $fs-4px;
// }
// .custom-reference-links {
//   font-family: $nunitoSans-Regular;
//   color: $blue !important;
//   @include setFont(normal, normal, normal, normal, center);
//   line-height: $fs-28px;
//   text-decoration: underline;
//   font-size: $fs-14px !important;
//   white-space: normal;
//   word-break: break-all;
//   &:hover {
//     text-decoration: underline !important;
//   }
// }
// img {
//   &:focus {
//     outline: none !important;
//   }
// }
// .custom-link-item {
//   color: $nero-grey !important;
// }
// .fw-800 {
//   font-weight: 800;
// }
// .mobile-text-position {
//   text-align: left;
// }
// .contant-email--title {
//   @include mobileScreen {
//     font-size: $fs-24px !important;
//     @include xsmall {
//       font-size: $fs-16px !important;
//     }
//     @include phone {
//       font-size: $fs-16px !important;
//     }
//     @include iphone {
//       font-size: $fs-16px !important;
//     }
//     @include phablet {
//       font-size: $fs-16px !important;
//     }
//   }
// }
// .mb-12px {
//   margin-bottom: $fs-12px;
// }
// .pl-12px {
//   padding-left: $fs-12px;
// }
// .pr-12px {
//   padding-right: $fs-12px;
// }
// .br-line {
//   height: $fs-28px;
//   border: 1px solid $white-smoke;
// }
// .iisc-logo--width {
//   width: $fs-38px;
// }
// .full-width {
//   width: 100%;
// }
// .right-icon--width {
//   width: $fs-26px;
//   cursor: pointer;
// }
// .opening-card-br {
//   font-size: $fs-14px !important;
//   &:nth-child(even) {
//     .opening-card {
//       border-left: $fs-5px solid $medium-slate-blue;
//     }
//   }
//   &:nth-child(odd) {
//     .opening-card {
//       border-left: $fs-5px solid $macaroni-orange;
//     }
//   }
//   &:nth-child(2n + 2) {
//     .opening-card {
//       border-left: $fs-5px solid $razzle-rose;
//     }
//   }
// }
// .previous-challenges-card-br {
//   font-size: $fs-14px !important;
//   &:nth-child(1) {
//     .opening-card {
//       border-top: $fs-5px solid $pattens-blue;
//     }
//   }
//   &:nth-child(2) {
//     .opening-card {
//       border-top: $fs-5px solid $pattens-blue;
//     }
//   }
//   &:nth-child(3) {
//     .opening-card {
//       border-top: $fs-5px solid $pattens-blue;
//     }
//   }
// }
// .challenges-card-br {
//   &:nth-child(1) {
//     .opening-card {
//       border-top: $fs-5px solid $medium-slate-blue;
//       font-size: $fs-18px !important;
//     }
//   }
//   &:nth-child(2) {
//     .opening-card {
//       border-top: $fs-5px solid $macaroni-orange;
//     }
//   }
//   &:nth-child(3) {
//     .opening-card {
//       border-top: $fs-5px solid $razzle-rose;
//     }
//   }
// }
// .opening-card-position {
//   min-height: $fs-56px;
//   padding: 0 $fs-10px;
// }
// .opening-card-position-date {
//   padding: 0 $fs-10px;
// }
.document-card-position {
  min-height: 5rem;
  top: 265px;
  left: 274px;
  height: 45px; 
}
// .contact-icon--width {
//   width: $fs-18px;
// }
// .contact-icon-email---width {
//   width: $fs-20px;
// }
// .map-container {
//   height: 52vh;
//   @include mobileScreen {
//     height: 30vh;
//   }
//   @include desktop-max {
//     height: 30vh;
//   }
// }

// .custom-table-thead {
//   tr {
//     font-family: $nunitoSans-Bold;
//     background-color: $theme-blue;
//     color: $white;
//     font-size: $fs-14px;
//     text-align: center;
//   }
// }
// .custom-btn-small {
//   @include setPadding(
//     $fs-4px !important,
//     $fs-4px !important,
//     $fs-4px !important,
//     $fs-4px !important
//   );
// }
// .custom-edit-btn{
//   box-shadow: 0px $fs-8px $fs-6px #72C3DB29;
//   font-family: $nunitoSans-Bold;
//   font-size: $fs-14px !important;
//   border-radius: $fs-4px !important;
//   @include setFont(500, normal, normal, $fs-1px, center);
//   @include setPadding(
//     $fs-8px !important,
//     $fs-8px !important,
//     $fs-8px !important,
//     $fs-8px !important
//   );
//   @include setBtnColor(
//     $kelly-green !important,
//     $kelly-green !important,
//     $white !important
//   );
//   &:hover {
//     @include setBtnColor(
//       $white !important,
//       //background-color
//       $white !important,
//       //border-color
//       $kelly-green !important//color
//     );
//     border-color: $kelly-green !important;
//   }
//   &:focus {
//     box-shadow: none !important;
//     @include setBtnColor(
//       $white !important,
//       $white !important,
//       $kelly-green !important
//     );
//     border-color: $kelly-green !important;
//   }
//   &:disabled {
//     @include setBtnColor(
//       $kelly-green !important,
//       $kelly-green !important,
//       $white !important
//     );
//     cursor: not-allowed;
//     border-color: $kelly-green !important;
//   }
// }
// .fw-600 {
//   font-weight: 600;
// }
// .map-loader {
//   padding: $fs-32px;
//   text-align: center;
// }
// .maps-position {
//   left: 0;
//   right: 0;
//   width: "100%";
//   height: "100%";
// }
// .dot-icon {
//   font-size: $fs-14px !important;
//   color: #FD4B9A !important;
//   // fill: $dark-grey !important;
//   margin-top: $fs-6px !important;
//   margin-right: $fs-4px !important;
// }
// .opening-info-container {
//   border: $fs-1px solid $macaroni-orange;
//   padding: $fs-18px;
//   border-radius: $fs-4px;
// }
// .text-capitalize {
//   text-transform: capitalize;
// }
// .min-height-auto {
//   min-height: auto;
//   @include mobileScreen {
//     min-height: auto !important;
//   }
// }
// .team-mobile-position {
//   margin-right: auto;
//   @include mobileScreen {
//     margin: auto;
//   }
// }
// .iisc-img--width {
//   width: 70%;
//   margin: auto;
// }
// .mobile--footer--logo-position {
//   @include mobileScreen {
//     justify-content: center;
//     margin-bottom: $fs-16px;
//   }
// }
// .mega-menu-container {
//   position: relative;
//   width: 100%;
//   left: 0;
//   right: 0;
//   top: 0;
//   background: transparent linear-gradient(249deg, #ff8b8e 0%, #fa85b8 100%) 0%
//     0% no-repeat padding-box;
//   box-shadow: 0px 0px 6px #00000029;
//   @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px);
//   @include mobileScreen {
//     overflow-y: scroll;
//     @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px !important);
//     position: fixed;
//     width: 100%;
//   left: 0;
//   right: 0;
//   top: 0;
//     height: 100%;
//   }
//   &.mega-menu--show {
//     opacity: 1 !important;
//   }
//   &.mega-menu--hide {
//     position: absolute;
//     top: -11rem;
//     opacity: 0 !important;
//     z-index: 999;
//   }
// }
// .position {
//   position: absolute;
// }
// .mega-menu-link--position {
//   font-family: $nunitoSans-Regular;
//   font-size: $fs-12px ;
//   cursor: pointer;
//   text-transform: capitalize;
//   padding-bottom: 0.015rem;
//   @include mobileScreen {
//     font-size: $fs-15px;
//   }
//   .mega-menu-link {
//     width: 100%;
//     display: flex;
//     @include setPadding($fs-2px, $fs-2px, $fs-2px, $fs-2px);
//     width: 224px;
//     color: $white;
//     cursor: pointer;
//     @include mobileScreen {
//       @include setPadding($fs-3px, 0, $fs-3px, 0);
//     }
//     &:hover {
//       color: $white !important;
//       cursor: pointer;
//     }
//     &.mega-menu-link-active {
//       cursor: pointer;
//       font-family: $nunitoSans-Black;
//       font-weight: 800;
//       color: $white !important;
//     }
//   }
// }
// .mega-menu-title {
//   font-family: $nunitoSans-Bold;
//   color: $white !important;
//   font-size: $fs-14px !important;
//   @include setFont(500, normal, normal, normal, left);
//   @include setMargin(0, 0, 8px, 0);
//   @include setPadding(0, 0, 0, 0);
//   @include mobileScreen {
//     font-size: $fs-18px;
//   }
// }
// .mega-menu-logo {
//   width: 2rem;
// }
// .mega-menu-close-icon--container {
//   position: relative;
//   right: $fs-28px;
//   top: -0.5rem;
//   width: 0px;
//   height: 35px;
//   opacity: 1;
// }
// .mega-menu-close-icon {
//   width: 2rem;
// }
// .document-section-title {
//   font-family: $nunitoSans-Regular;
//   color: $nero-grey;
//   opacity: 1;
//   letter-spacing: 0px;
//   font-size: $fs-15px;
//   @include setFont(500, normal, normal, normal, center);
//   @include setMargin(0, 0, 0, 0);
//   @include setPadding(0, 0, 0, 0);
//   line-height: $fs-20px !important;
// }
// .document-sub-section-title {
//   font-family: $nunitoSans-Regular;
//   color: $Matterhorn;
//   letter-spacing: 0px;
//   font-size:  14px !important ;
//   @include setFont(500, normal, normal, normal, left);
//   @include setMargin(0, 0, 0, 0);
//   @include setPadding(0, 0, 0, 0);
//   line-height: $fs-12px !important;
// }
// .media-card-position {
//   min-height: $fs-30px;
//   // padding: 0 $fs-10px;
// }
// .media-links {
//   text-decoration: none;
//   // border-bottom: #EA215A 0.125em solid;
// }
// .media-section-title {
//   font-family: $nunitosansLight;
//   color: $blue;
//   @include setFont(500, normal, normal, normal, center);
//   @include setMargin(0, 0, 0, 0);
//   @include setPadding(0, 0, 0, 0);
//   line-height: $fs-28px !important;
// }
// .researcharea-bg {
//   // background-color: #796476;
//   background: transparent linear-gradient(180deg, #ff6894 0%, #f44ce1 100%) 0%
//     0% no-repeat padding-box;
// }
// .area-card-br {
//   &:nth-child(n+1) {
//     .opening-card {
//       border-top: $fs-5px solid $medium-slate-blue;
//       color: #FFFFFF;
//       background: transparent linear-gradient(180deg, #ffbe86 0%, #ff988c 100%)
//         0% 0% no-repeat padding-box;
//     }
//   }
//   &:nth-child(3n+3) {
//     .opening-card {
//       border-top: $fs-5px solid $macaroni-orange;
//       color: #FFFFFF;
//       background: transparent linear-gradient(180deg, #ff6894 0%, #f44ce1 100%)
//         0% 0% no-repeat padding-box;
//     }
//   }
//   &:nth-child(2n+2) {
//     .opening-card {
//       border-top: $fs-5px solid $razzle-rose;
//       color: #FFFFFF;
//       background: transparent linear-gradient(180deg, #565de9 0%, #9c55e5 100%)
//         0% 0% no-repeat padding-box;
//     }
//   }
// }
// .txt-underline {
//   text-decoration: underline; 
// }
// .arrow-img--container {
//   width: $fs-28px;
//   padding: 0.15rem;
//   cursor: pointer;
//   $color: white;
// }
// .text-underline {
//   text-decoration: underline;
// }
// .play-icon-container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   margin: auto;
//   width: 4rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// }
.pdf-thumbnail-container {
  position: absolute;
  top: 3px;
  right: 2px;
  background: $crimson-red;
}
// .trash-icon-container {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 1.5rem;
//   height: 1.5rem;
//   border-radius: 50%;
//   background: $crimson-red;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   z-index: 99;
// }
.gallery-img {
width: 320px;
height: 240px;
// background: transparent 0% 0% no-repeat padding-box;
// opacity: 1;
}
.img-viewer-container{
  height: 500px;
  width: 100%;
  object-fit: contain;
  @include mobileScreen
  {
    height: 300px !important;
    width: 300px !important;
    object-fit: contain;
  }
}
.image-modal-container {
  @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px);
  width: 50% !important;
  @include desktop-max {
    width: 80% !important;
  }
  @include mobileScreen {
    width: 90% !important;
  }
}

.photos-card-br {  
  &:nth-child(2n+1) {
    .opening-card{
      border-top: $fs-5px solid #E50000;
      font-size: $fs-18px !important;
    }
  }
  &:nth-child(2n+2) {
    .opening-card {
      border-top: $fs-5px solid #F78058;
    }
  }
  &:nth-child(2n+3) {
    .opening-card {
      border-top: $fs-5px solid #00B8BD;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.photos-card-br-position { 
  min-height: $fs-32px;
  background: transparent linear-gradient(180deg, #F5F8FA 0%, #F5F8FA 100%)
}

.photosbox-bg {
  background-color: #f8f8f8;
 //background: transparent linear-gradient(180deg, #ebdee2 0%, #f0d7ed 100%) 0% 0% no-repeat padding-box;
}


// .next-prev-container {
//   color: #9c55e5;
//   @include mobileScreen {
//     left: -0.75rem !important;
//   }
// }
// .pdf-thumbnail-style {
//   width:20px !important;
//   height: 20px !important;
// }
// #tst-strip-openings > div > div > div > div.pb-4.pt-4.col-12.col-sm-12.col-md-12.col-lg-12.col-xl-12 > div > div > div > div:nth-child(1) > div > div {
//     width: 96px !important;
//   height: 99px !important;
// }
#tst-strip-documents > div > div > div > div.pb-4.pt-4.col-12.col-sm-12.col-md-12.col-lg-12.col-xl-12 > div > div > div > div.pdf-thumbnail.pt-2.pb-2.col-6.col-sm-6.col-md-5.col-lg-5.col-xl-5 > div > div > div > canvas {
  // @media only screen and (max-width: 768px) {
  width: 100px !important;
  height: 100px !important;
  }
//  }
// .project-title-container{
//   width:200px;
//   height: 165px;
// }
// // #tst-strip-gallery > div > div.pb-5.px-0.m-auto.col-12.col-sm-12.col-md-12.col-lg-11.col-xl-11 > div > div > div > div.slick-list > div > div:nth-child(2){
// //   width: 657px !important;
// // }
// .aboutus-img {
//   width: 600px;
// height: 320px;
// }

.object-fit_fill { object-fit: fill }
.object-fit_contain { object-fit: contain }
.object-fit_cover { object-fit: cover }
.object-fit_none { object-fit: none }
.object-fit_scale-down { object-fit: scale-down }
.gal-img { width: 320px; height: 240px; background-color: #F7F7F8;}
.image {
  float: left;
  
  &:nth-child(2n) {
    clear: left;
  }
  
  &:nth-child(2n+1){
    margin-right: 0;
  }
}
.mobile-gal-img {
  width: 174px; height: 131px; background-color: #F7F7F8;
}
.opening-card:hover {
  background: white;
}

.tab-container {
  border: white !important;
  flex-wrap: nowrap !important;
  @include mobileScreen {
    justify-content: space-between;
    @include desktop {
      justify-content: flex-start;
    }
  }
  .tab-nav-item {
    .tab-nav-link {
      border: white !important;
      color: #505050 !important;
      font-size: $fs-16px !important;
      font-family: $nunitoSans-Regular !important;
      padding-bottom: $fs-8px;
      @include setFont(500, normal, normal, normal, left);
      cursor: pointer;
      background-color: $transparent;
      @include mobileScreen {
        padding-left: $fs-6px !important;
        padding-right: $fs-6px !important;
        font-size: $fs-14px !important;
        @include desktop {
          padding-left: $fs-14px !important;
          padding-right: $fs-14px !important;
        }
      }
      &.active {
        border: white !important;
        color: #FD4B9A !important;
        font-family: $nunitoSans-Bold !important;
        @include setFont(500, normal, normal, normal, left);
        background-color: $transparent;
        // &:hover {
        //   border-bottom: $fs-4px solid $medium-pink !important;
        // }
      }
    }
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
}
// .foo{
//   font-size:30px;
//   cursor:pointer;
//   color: #C4C4C5;
// }
// .search-input-field {
//   height: 33px !important;
//   border-radius: 17px !important;
//   background-color: #F1F1F1 !important;
// }
// .search-btn {
//   height: 33px;
//   /* margin: 0px; */
//   font-size: 14px;
//   margin-left: 3px;
//   margin-right: 3px;
//   padding-left: 16px;
//   padding-right: 17px;
//   border: none;
//   border-radius: 17px;
//   color: #FFFFFF;
//   background-color: #FD4B9A;
// }

// .contribute-sort {
//   text-align: left;
// font: normal normal 300 16px/30px Nunito Sans;
// letter-spacing: 0px;
// color: #292929;
// opacity: 1;
// font-size: 16px !important;
// }
// .contributors-style {
//   // text-align: center;
// font: normal normal 600 25px/95px Nunito Sans;
// letter-spacing: 0px;
// color: #292929;
// opacity: 1;
// }

// .contributors-place-style {
// text-align: left;
// font: normal normal normal 18px/95px Nunito Sans;
// letter-spacing: 0px;
// color: #292929;
// opacity: 1;
// }

// .table-heading {
//   font: normal normal 600 25px/95px Nunito Sans;
// }

// .contributors-card-br {
//   font-size: $fs-14px !important;
// }

// .contributors-card-position {
//   min-height: $fs-8px;
//   padding: 0 $fs-4px;
// }
// .academics-big-img{
//   @media screen and (min-device-width : 640px) {
//     width: 1200px;
//     height: 500px;
//     background: #F8F7F7 0% 0% no-repeat padding-box;
//     opacity: 1;
//   }

// }
// .a-tag-container {
//   width: 100%;
//   text-align: center;
// }
// .a-tag {
//   display: inline-block;
// }
.large-img-container {
  width: 928px;
  height: 374px;
}
.large-img {
  width: 100%;
  height: 100%;
}


// .publications-card {
//   background-color: #F8FDFF;
// }
.right-icon--width {
  width: $fs-26px;
  cursor: pointer;
  text-align: right !important;
}
.publications-card-position {
  // min-height: $fs-8px;
  padding:  $fs-4px;
}
.publications-card-br {
  font-size: $fs-14px !important;
  background-color: #F8FDFF;
}

.publications-card {
  background-color: #F8FDFF;

}

.challenges-card-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .img-item {
    width: 400px;
    height: 300px;
    object-fit: fill;
    @include mobileScreen {
      width: 100%;
      height: 200px;
      @include desktop {
        width: 40%;
        height: 400px;
        object-fit: cover;
      }
    }
  }
}


.popup-advisor-team-img-container {
  position: relative;
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
 
  @include mobileScreen {
    margin: auto;
  }
  .first-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 1;
  }
  .second-circle-container {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  .third-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 0.2;
  }
}

.popup-team-img-container {
  position: relative;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
 
  @include mobileScreen {
    margin: auto;
  }
  .first-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 1;
  }
  .second-circle-container {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  .third-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 0.2;
  }
}
.popup-indivi-img-container {
  position: relative;
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
 
  @include mobileScreen {
    margin: auto;
  }
  .first-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 1;
  }
  .second-circle-container {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  .third-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 0.2;
  }
}

/* Evolution of Tools */
.evltl-h1{
  margin: 1%;
  text-align: center;
  font-size: 2rem;
  font-weight: 10;
  top: 0; 
  color: #000C65;
  }

.timeline {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  position: relative; 
}
.timeline__event {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 6px;
  align-self: center;
  width: 75vw; 
}
.timeline__event:nth-child(2n + 1) {
  flex-direction: row-reverse; 
}
.timeline__event:nth-child(2n + 1) .timeline__event__date {
  border-radius: 0 6px 6px 0; 
}
.timeline__event:nth-child(2n + 1) .timeline__event__content {
  border-radius: 6px 0 0 6px; 
}
.timeline__event:nth-child(2n + 1) .timeline__event__icon:before {
  content: "";
  width: 4px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
  transform: translateX(-50%);
  animation: fillTop 2s forwards 4s ease-in-out;
}
        .timeline__event:nth-child(2n + 1) .timeline__event__icon:after {
          content: "";
          width: 15%;
          height: 4px;
          background: #f6a4ec;
          position: absolute;
          right: 0;
          z-index: -1;
          top: 50%;
          left: auto;
          transform: translateY(-50%);
          animation: fillLeft 2s forwards 4s ease-in-out; }
      .timeline__event__title {
        font-size: 1.2rem;
        line-height: 1.4;
        text-transform: uppercase;
        font-weight: 600;
        color: #9251ac;
        letter-spacing: 1.5px; }
        .timeline__event__content {
        padding: 20px;
        box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
        background: #fff;
        width: calc(70vw - 84px);
        border-radius: 0 6px 6px 0; }
      .timeline__event__date {
        color: #f6a4ec;
        font-size: 1.5rem;
        font-weight: 600;
        background: #9251ac;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        padding: 0 20px;
        border-radius: 6px 0 0 6px; }
      .timeline__event__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #9251ac;
        padding: 20px;
        align-self: center;
        margin: 0 20px;
        background: #f6a4ec;
        border-radius: 100%;
        width: 40px;
        box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
        padding: 40px;
        height: 40px;
        /* position: relative; */} 
        .timeline__event__icon i {
          font-size: 32px; }
        .timeline__event__icon:before {
          content: "";
          width: 4px;
          height: 100%;
          background: #f6a4ec;
          position: absolute;
          top: 0%;
          z-index: -1;
          left: 50%;
          transform: translateX(-50%);
          animation: fillTop 2s forwards 4s ease-in-out; }
        .timeline__event__icon:after {
          content: "";
          width: 15%;
          height: 4px;
          background: #f6a4ec;
          position: absolute;
          left: 0%;
          z-index: -1;
          top: 50%;
          transform: translateY(-50%);
          animation: fillLeftOdd 2s forwards 4s ease-in-out; }
      .timeline__event__description {
        flex-basis: 100%; }
      .timeline__event--type2:after {
        background: #555ac0; }
      .timeline__event--type2 .timeline__event__date {
        color: #87bbfe;
        background: #555ac0; }
      .timeline__event--type2:nth-child(2n + 1) .timeline__event__icon:before, .timeline__event--type2:nth-child(2n + 1) .timeline__event__icon:after {
        background: #87bbfe; }
      .timeline__event--type2 .timeline__event__icon {
        background: #87bbfe;
        color: #555ac0; }
        .timeline__event--type2 .timeline__event__icon:before, .timeline__event--type2 .timeline__event__icon:after {
          background: #87bbfe; }
      .timeline__event--type2 .timeline__event__title {
        color: #555ac0; }
      .timeline__event--type3:after {
        background: #24b47e; }
      .timeline__event--type3 .timeline__event__date {
        color: #aff1b6;
        background-color: #24b47e; }
      .timeline__event--type3:nth-child(2n + 1) .timeline__event__icon:before, .timeline__event--type3:nth-child(2n + 1) .timeline__event__icon:after {
        background: #aff1b6; }
      .timeline__event--type3 .timeline__event__icon {
        background: #aff1b6;
        color: #24b47e; }
        .timeline__event--type3 .timeline__event__icon:before, .timeline__event--type3 .timeline__event__icon:after {
          background: #aff1b6; }
      .timeline__event--type3 .timeline__event__title {
        color: #24b47e; }
      .timeline__event:last-child .timeline__event__icon:before {
        content: none; }
        .dropdown-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 35px;
      }
      
      #sentenceDropdown {
          width: calc(30vw - 14px);
          padding: 10px;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          border: 2px solid #e9eef3;
          border-radius: 6px;
          background-color: #ffff;
          color: #02101f;
          box-sizing: border-box;
          appearance: none; /* Remove default arrow in some browsers */
          -webkit-appearance: none;
          -moz-appearance: none;
      }
      
      #sentenceDropdown option {
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          color: #9251ac;
          background-color: #fff;
      }
      
      #sentenceDropdown option[value=""] {
          color: #888;
      }
      
      #sentenceDropdown:hover,
      #sentenceDropdown:focus {
          outline: none;
          cursor: pointer;
          box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      }

/* Sentaudio.css */

/* Style for the container of the audio component */
.sentaudio-container {
  text-align: center;
}
/* Style for the "Play" button */
.sentaudio-container button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
}

/* Style for the pop-up background */
.popup-background-evltl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent gray background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensures the pop-up is above everything else */
}

/* Style for the pop-up content */
.popup-evltl {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative; /* Needed for the "Close" button */
}

/* Style for the "Close" button in the pop-up */
.close-button-evltl {
  position: absolute;
  right: 20px;
  top: -50px;
  z-index: 1000; /* Ensures the button is above the pop-up content */
}
/* Style for disabling background scroll when pop-up is open */
.overflow-hidden-evltl {
  overflow: hidden !important;
}

/* TTS info designing */
.loading-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  // height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(253, 253, 253);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  z-index: 20;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Use rgba for a semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.loading-indicator {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  // font-size: 2.5rem;
  //font-weight: 500;
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out 0.5s infinite;
}
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.loading-text {
  text-align: center;
 // font-family: 'Roboto', sans-serif;
  // font-size: 2.5rem;
  //font-weight: 500;
  // opacity: 0;
  // animation: fadeIn 1.5s ease-in-out 0.5s infinite;
}
.loading-mod {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(253, 253, 253);
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
}
.loading-content {
  text-align: center;
  padding: 10px;
  width: 100%; /* Set the width to 100% to prevent shifting */
}

.loading-ovly {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto;
}

.loading-indi {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  margin: auto; /* Center the images horizontally */
  padding: 10px;
  
  width: 50%;
  //animation: fadeIn 1.5s ease-in-out 0.5s infinite;
}
.loading-indilogo {
  width: 5rem;
    height: 1.75rem;
    padding: 5px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.text-bold{
  font-weight: bold;
}


.audio-player-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 50%;
  z-index: 999;
}

.audio-player-modal button {
  margin-top: 10px;
  padding: 10px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.audio-player-modal button:hover {
  background-color: #cc0000;
}

.video-player-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  // width: 50%;
  z-index: 999;
}

.video-player-modal video {
  // width: 100%; /* Adjust as needed */
  max-height: 50vh; /* Adjust as needed */
}

.video-player-modal button {
  margin-top: 10px;
  padding: 10px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.video-player-modal button:hover {
  background-color: #cc0000;
}

.modal-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
}

.modal-background.visible {
  display: block;
}


.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
 // height: 100vh;
   padding: 15px;
  // margin: 10px;
}
.form-box{
  border: 5px solid rgb(0, 38, 144);
  padding: 20px;
  // display: inline-block;
  // padding-left: 20px;
  // padding-top: 20px;
  // padding-right: 20px;
  box-shadow: inset -2px 19px 28px 14px rgb(47 65 111 / 10%); 
  transition: box-shadow 0.3s ease; 
  border-radius: 10px;
  width: 750px;
  // margin: 0 auto;
}
.form-str{
  padding: 50px;
  // border-radius: 10px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color:#000c65;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  /* align-items: center; */
}
/* Style for the select element */
#wordBox {
  width: 200px; /* Set the width to 40px */
  padding: 15px; /* Add some padding for better visual appearance */
  border: 3px solid #49368c; /* Add a border */
  border-radius: 5px; /* Add rounded corners */
  cursor: pointer; /* Change cursor on hover */

  /* Define a custom dropdown arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /*background-image: url('dropdown-arrow.png'); /* Replace with your arrow icon */
  background-position: right center;
  background-repeat: no-repeat;
}

/* Style for select options on hover */
#wordBox:hover {
  background-color: #f0f0f0; /* Add a hover effect */
}

/* Style for open dropdown */
#wordBox:active, #wordBox:focus {
  background-color: #fff; 
  outline: none; 
  border-color: #007bff; 
}

/* Move other content when dropdown is open */
#wordBox:active ~ .content {
  margin-top: 50px; 
}
fieldset {
  border: 2px solid #ccc !important;; 
  border-radius: 10px !important; 
  padding: 10px !important;
  margin-bottom: 20px !important;
}
.selctfield{
  font-size: 0.8em;
  font-family: 'Roboto', sans-serif;
  padding-right: 15px !important;
  margin: 5px;
}
legend {
  font-weight: bold !important;
  font-size: 1.2rem !important;
  color: #333 !important;
  padding: 0 10px !important;
  background-color: #f0f0f0 !important;
  border: 1px solid #ccc !important;
  border-radius: 5px!important;
}
.model-buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.model-button {
  margin-right: 10px; /* Adjust the margin as needed for spacing between radio buttons */
}

/* Add the following styles if you want to align the radio buttons and labels vertically */
.model-button input,
.model-button label {
  vertical-align: middle;
}
.textarea_box{
  position: relative;
  width: 600px;
  height: 50px;
  margin-bottom: 50px;
  border: 2px solid rgb(0, 38, 144);
  outline: none;
  background: none;
  padding: 15px;
  border-radius: 10px;
  font-size: 0.8em;
  top: 14px;            
  transition: 0.6s;
  font-family: 'Roboto', sans-serif;
  z-index: 1;
}
.textarea_box::placeholder {
  color: gray;
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
  z-index: 1;
}
.submit_button{
  width: 40%;
  height: 40px;
  margin: 10px;
  border-radius: 10px;
  background: rgb(0, 38, 144);
  color: #fff;
  border: #fff;
  font-size: 1.2rem;
  font-weight: 400;
}
.submit_button:hover{
  color: #024052;
  background: radial-gradient(ellipse at center, rgb(211, 239, 240) 0%, rgb(76, 150, 233) 55%, rgb(0, 12, 101) 100%);
}
