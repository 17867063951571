.section-title {
  font-family: var(--bs-family-Jost-Medium);
  // color: $navy-blue;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.indivi-section-title {
  font-family: var(--bs-family-Jost-Light);
  // color: $navy-blue;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.title-dark-grey {
  color: $dark-grey;
}

.title-grey {
  color: $grey;
}

.title-light-blue {
  color: $light-blue;
}

.title-white {
  color: var(--bs-white);
}

.title-black {
  color: var(--bs-black);
}

.title-navy-blue-medium {
  color: $navy-blue-medium;
}

.title-navy-blue {
  color: #000C65;
}

.title-crusta-orange {
  color: $crusta-orange;
}

.title-iris-blue {
  color: $iris-blue;
}

.title-theme-blue {
  color: $theme-blue;
}

.title-dodger-blue {
  color: $dodger-blue;
}

.jostRegular {
  font-family: $jostRegular;
}

.jostBold {
  font-family: $jostBold;
}

.jostLight {
  font-family: $jostLight;
}

.nunitosansItalic {
  font-family: $nunitosansItalic;
}

.nunitosansLight {
  font-family: $nunitosansLight;
}

.align-left {
  text-align: var(--bs-align-left);
}

.bg-navy-blue-medium {
  background-color: $navy-blue-medium;
}

.bg-white-smoke {
  background-color: $white-smoke !important;
}

.txt-underline {
  text-decoration: underline;
}

.common-line {
  width: calc(100% / 9);
  padding: 0.5rem;
}

.full-width {
  width: 100%;
}

.lh-30px {
  line-height: $fs-30px !important;
}

.lh-25px {
  line-height: $fs-25px !important;
}

.width-32px {
  width: $fs-32px;
}

.card-container {
  border: none !important;
  background-color: #F4FBFF !important;
}

.cursor-pointer {
  cursor: pointer;
}

.br-bt-white-smoke {
  border-bottom: $fs-2px solid $white-smoke;
}

.link {
  &:hover {
    text-decoration: none !important;
  }
} 

.bg-theme-blue {
  background-color: $theme-blue !important;
}

.br-bt-crusta-orange {
  border-bottom-color: $crusta-orange;
  border-bottom: 2px;
  border-bottom-style: solid;
  @include mobileScreen {
    border: none;
  }
}

.br-bt-iris-blue {
  border-bottom-color: $iris-blue;
  border-bottom: 2px;
  border-bottom-style: solid;
  @include mobileScreen {
    border: none;
  }
}

.br-bt-theme-blue {
  border-bottom-color: $theme-blue;
  border-bottom: 2px;
  border-bottom-style: solid;
  @include mobileScreen {
    border: none;
  }
}

.br-bt-dodger-blue {
  border-bottom-color: $dodger-blue;
  border-bottom: 2px;
  border-bottom-style: solid;
  @include mobileScreen {
    border: none;
  }
}

.mobile-no-padding {
  @include mobileScreen {
    padding: 0 !important;
  }
}

.mobile-no-margin {
  @include mobileScreen {
    margin: 0 !important;
  }
}

.mobile-16px {
  @include mobileScreen {
    margin-bottom: 1rem;
  }
}

.custom-about-table {
  thead {
    tr {
      background-color: $white !important;
    }
    th {
      border: none !important;
      padding-top: $fs-8px !important;
      padding-bottom: $fs-8px !important;
    }
  }
  tbody {
    tr {
      &:nth-child(2n) {
        background-color: $white !important;
      }
    }
    td {
      border: none !important;
      padding-top: $fs-8px !important;
      padding-bottom: $fs-8px !important;
    }
  }
}

ol.alpha-order {
  .alpha {
    list-style: lower-alpha;
  }
}
ol {
  @include mobileScreen {
    padding-left: $fs-12px !important;
  }
}
// li {
//   display: block;
// }
// li:before {
//   content: counters(item, ".") " ";
//   counter-increment: item;
// }

// ol.num-order{
//   counter-reset: item;
//   .num{
//     display: block;
//     &:before{
//       content: counters(item, ".") " ";
//        counter-increment: item;
//     }
//   }
// }

.header-container {
  position: relative;
  width: 100%;
  height: 4rem;
  display: table;
  align-items:flex-end;
  background: $white;
  justify-content: center;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 0px #00000029;
  @include mobileScreen {
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  &.mega-menu--hide {
    position: relative;
    opacity: 0 !important;
  }
}
.menu-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
font-size: 24px;
}

.foo{
  color: #C4C4C5;
}

.tab-container1 {
  border: none !important;
  // flex-wrap: nowrap !important;
  @include mobileScreen {
    justify-content: space-between;
    @include desktop {
      justify-content: flex-start;
    }
  }
  .tab-nav-item1 {
    .tab-nav-link1 {
      border: none !important;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: #707070 !important;
      font-size: $fs-25px !important;
      font-family: $jostRegular !important;
      padding-bottom: $fs-8px;
      border-bottom: $fs-4px solid $transparent !important;
      @include setFont(500, normal, normal, normal, left);
      cursor: pointer;
      background-color: $transparent;
      @include mobileScreen {
        padding-left: $fs-6px !important;
        padding-right: $fs-6px !important;
        font-size: $fs-14px !important;
        @include desktop {
          padding-left: $fs-14px !important;
          padding-right: $fs-14px !important;
        }
      }
      &.active {
        border: none !important;
        color: $black !important;
        font-family: $nunitoSans-Bold !important;
        border-bottom: $fs-4px solid #F78058 !important;
        @include setFont(500, normal, normal, normal, left);
        background-color: $transparent;
        // &:hover {
        //   border-bottom: $fs-4px solid $medium-pink !important;
        // }
      }
    }
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
}

.search-input-field {
  height: 33px !important;
  border-radius: 17px !important;
  background-color: #F1F1F1 !important;
}
.search-btn {
  height: 33px;
  /* margin: 0px; */
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
  padding-left: 16px;
  padding-right: 17px;
  border: none;
  border-radius: 17px;
  color: #FFFFFF;
  background-color:#F78058;
}

.contribute-sort {
  text-align: left;
font: normal normal 300 16px/30px Nunito Sans;
letter-spacing: 0px;
color: #292929;
opacity: 1;
font-size: 16px !important;
}
.contributors-style {
  // text-align: center;
font: normal normal 600 25px/95px Nunito Sans;
letter-spacing: 0px;
color: #292929;
opacity: 1;
}

.contributors-place-style {
text-align: left;
font: normal normal normal 18px/95px Nunito Sans;
letter-spacing: 0px;
color: #292929;
opacity: 1;
}

.table-heading {
  font: normal normal 600 25px/95px Nunito Sans;
}

.contributors-card-br {
  font-size: $fs-14px !important;
  // color:  #F8FDFF;
  background-color: #F8FDFF;
}

.contributors-card-position {
  min-height: $fs-8px;
  padding: 0 $fs-4px;
  // background: transparent linear-gradient(180deg,#F8FDFF 0%, #F8FDFF 100%)
}

.academics-big-img{
  @media screen and (min-device-width : 640px) {
    width: 1200px;
    height: 500px;
    background: #F8F7F7 0% 0% no-repeat padding-box;
    opacity: 1;
  }

}
.right-arrow--width {
  width: $fs-26px;
  
  cursor: pointer;
}

.documents-border{
  box-shadow: 0px 0px 3px #00000029;
}
.pdf-thumbnail-style {
  width:20px !important;
  height: 20px !important;
}

.document-section-title {
  font-family: $nunitoSans-Regular;
  color: $nero-grey;
  opacity: 1;
  letter-spacing: 0px;
  font-size: $fs-15px;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-20px !important;
}
.document-sub-section-title {
  font-family: $nunitoSans-Regular;
  color: $Matterhorn;
  letter-spacing: 0px;
  font-size:  14px !important ;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-12px !important;
}

.pdf-thumbnail {
  width: 10px;
  height: 12px;
  @include mobileScreen {
  width: 80px;
  height: 82px;
   @include setPadding(0, 0, 0, 0);
  }
}
.documents-text {
  @include mobileScreen {
  padding-left: 25pxpx !important;
  }
}

.challenges-card-br {
  
  &:nth-child(even) {
    .opening-card {
      border-top: $fs-5px solid $medium-slate-blue;
      font-size: $fs-18px !important;
    }
  }
  &:nth-child(odd) {
    .opening-card {
      border-top: $fs-5px solid $macaroni-orange;
    }
  }
  &:nth-child(2n+3) {
    .opening-card {
      border-top: $fs-5px solid $razzle-rose;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.tools-card-br {
  
  &:nth-child(odd) {
    .tools-card {
      border-top: $fs-5px solid #E50000;
      font-size: $fs-18px !important;
    }
  }
  &:nth-child(even) {
    .tools-card {
      border-top: $fs-5px solid #C02900;
    }
  }
  &:nth-child(2n+3) {
    .tools-card {
      border-top: $fs-5px solid #E50000;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.tools-card-position { 
  min-height: $fs-32px;
  background: transparent linear-gradient(180deg, #F5F8FA 0%, #F5F8FA 100%)
  
}
// .opening-card-position-date {
//   padding: 0 $fs-10px;
// }

.tools-info-container {
  border: $fs-1px solid $dim-grey;
  background-color: #F5F8FA;
  padding: $fs-10px;
}

.datasets-card-br {
  
  &:nth-child(2n+1) {
    .datasets-card {
      border-top: $fs-5px solid #E50000;
      font-size: $fs-18px !important;
    }
  }
  &:nth-child(2n+2) {
    .datasets-card {
      border-top: $fs-5px solid #C02900;
    }
  }
  &:nth-child(2n+3) {
    .datasets-card {
      border-top: $fs-5px solid #F78058;
    }
  }
  &:nth-child(2n+4) {
    .datasets-card {
      border-top: $fs-5px solid #00B8BD;
    }
  }
  &:nth-child(2n+5) {
    .datasets-card {
      border-top: $fs-5px solid #00EBE9;
    }
  }
  &:nth-child(2n+6) {
    .datasets-card {
      border-top: $fs-5px solid #23B6FF;
    }
  }
  &:nth-child(2n+7) {
    .datasets-card {
      border-top: $fs-5px solid #4C96E9;
    }
  }
  &:nth-child(2n+8) {
    .datasets-card {
      border-top: $fs-5px solid #002690;
    }
  }
  &:nth-child(2n+9) {
    .datasets-card {
      border-top: $fs-5px solid #000C65;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.datasets-card-position { 
  min-height: $fs-32px;
  background: transparent linear-gradient(180deg, #F5F8FA 0%, #F5F8FA 100%)
}
// .opening-card-position-date {
//   padding: 0 $fs-10px;
// }

.datasets-info-container {
  border: $fs-1px solid $dim-grey;
  background-color: #F5F8FA;
  padding: $fs-10px;
}

.contributors-info-container {
  border: $fs-1px solid $dim-grey;
  background-color: #F8FDFF;
  padding: $fs-18px;
  border-radius: 10px;
  min-width: $fs-900px;
  @include mobileScreen {
    border: $fs-1px solid $dim-grey;
    background-color: #F8FDFF;
    padding: $fs-18px;
    border-radius: 10px;
    min-width: $fs-16px;

  }
}




