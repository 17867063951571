.footer-container {
  .img-mobile--position {
    @include mobileScreen {
      justify-content: center;
      padding-bottom: $fs-16px;
    }
  }
  .common-width {
    img {
      width: 70%;
      margin: auto;
    }
  }
  .footer-mobile-alignment{
    @include mobileScreen {
        flex-direction: column-reverse;
        padding-bottom: $fs-16px;
        p{
            padding-bottom: $fs-2px; 
        }
      }
  }
}




