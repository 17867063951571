

.slick-prev,
.slick-next {
  width: $fs-1px !important;
  height: $fs-44px !important;
  z-index: 9 !important;
 }

.slick-prev:before,
.slick-next:before {
  font-size: $fs-1px !important;
  color: #f5ba8a !important;
}

// .slick-dots {
//   bottom: -1.5rem !important;
// }

// .slick-dots li button:before {
//   font-size: $fs-16px !important;
// }

.slick-dots li.slick-active button:before {
  color: $medium-pink !important;
}


.slick-disabled {
  display: none;
}


.next-next-container {
  right: -3.75rem !important;
  @include mobileScreen {
    right: -0.75rem !important;
  }
}

// .lr-arrow-white {
//   font-size: $fs-32px !important;
//   // color: $white;
//   @include mobileScreen {
//     color: $black-pearl;
//     // background-color: $white;
//   }
// }

.lr-arrow{
  font-size: $fs-20px !important;
  color: #FFFFFF;
  z-index: 9 !important;
}

.next-icon-container {
  right: 1.5rem !important;
}
.prev-icon-container {
  left: 0.5rem !important;
}

.next-next-container {
  right: -3.75rem !important;
  @include mobileScreen {
    right: -0.75rem !important;
  }
}

.next-prev-container {
  left: -3.25rem !important;
  @include mobileScreen {
    left: -0.75rem !important;
  }
}