/*--------------------------------------------------------------
	Common
--------------------------------------------------------------*/
@import "screen-sizes";
@import "mixins";
@import "fonts";
@import "colors";

:root {
  --bs-white: #ffffff;
  --bs-black: #000000;
  --bs-theme: #002690;
  --bs-align-left: left;
  --bs-align-right: right;
  --bs-family-Jost-Medium: Jost-Medium;

  font-size: $fs-16px; // 16px standard size

  // Control the font size across all screens based on
  // root font size
  //xsmall is for screen size less than 320px
  @include xsmall {
    font-size: $fs-12px;
  }
  //phone is for screen sizes 320px to 409px
  @include phone {
    font-size: $fs-14px;
  }
  //iphone is from 410px to 499px
  @include iphone {
    font-size: $fs-16px;
  }
  //phablet is from 500px to 649px
  @include phablet {
    font-size: $fs-16px;
  }
  //tablet is from 650px to 767px
  @include tablet {
    font-size: $fs-18px;
  }
  //desktop is from 768px to 1023px
  @include desktop {
    font-size: $fs-24px;
  }
  //ipad is from 1024px to 1365px
  @include iPad {
    font-size: $fs-16px;
  }
  // 1270px to 1440px
  @include desktop-max-portrait {
    font-size: $fs-18px !important;
  }

  //hdRes is from 1366px to 1919px
  @include hdRes {
    font-size: $fs-16px;
  }
  //hdResSmall is from 1920px to 2435px
  @include hdResSmall {
    font-size: $fs-22px;
  }
  //hdResMedium is from 2436px to 3838px
  @include hdResMedium {
    font-size: $fs-38px;
  }
  //hdResLarge is from 3840px
  @include hdResLarge {
    font-size: $fs-50px;
  }
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $white !important;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}

//scrollbar

.scoll-bar::-webkit-scrollbar {
  width: $fs-4px;
  height: $fs-4px;
  background-color: $transparent;
}
.scoll-bar::-webkit-scrollbar-thumb {
  background-color: var(--bs-white);
}
.scoll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $fs-2px var( --bs-theme) !important;
  box-shadow: inset 0 0 $fs-2px var( --bs-theme) !important;
  background-color: $transparent;
}

::-webkit-scrollbar {
  width: $fs-4px;
  height: $fs-4px;
  background-color: $transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var( --bs-theme);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $fs-2px var( --bs-theme) !important;
  box-shadow: inset 0 0 $fs-2px var( --bs-theme) !important;
  background-color: $transparent;
}

img {
  width: 100%;
  height: 100%;
}

.opening-card-position { 
  min-height: $fs-72px;
  background: transparent linear-gradient(180deg, #F5F8FA 100%, #F5F8FA 100%)
  
}
// .opening-card-position-date {
//   padding: 0 $fs-10px;
// }

.opening-info-container {
  border: $fs-1px solid $dim-grey;
  background-color: #F7F7F8;
  padding: $fs-18px;
  border-radius: 10px;
}

.custom-reference-links {
  font-family: $nunitoSans-Regular;
  color: #408BF9 !important;
  @include setFont(normal, normal, normal, normal, center);
  line-height: $fs-8px;
  text-decoration: underline;
  font-size: $fs-16px !important;
  white-space: normal;
  word-break: break-all;
  &:hover {
    text-decoration: underline !important;
  }
}

.gallery-card-img-container {
  width: 100%;
  display: flex;
  align-items: center;
  .img-item {
    width: 70%;
    height: 200px;
    object-fit: cover;
    @include mobileScreen {
      height: 250px;
      @include desktop {
        height: 300px;
      }
    }
  }
}
.gallery-card-img-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  .img-item {
    width: 100%;
    height: 300px;
    object-fit: cover;
    @include mobileScreen {
      height: 250px;
      @include desktop {
        height: 300px;
      }
    }
  }
}
.img-item--position{
  width: 100%;
  height: 300px;
  object-fit: contain;
  @include mobileScreen {
    height: 250px;
    @include desktop {
      height: 300px;
    }
  }
}

.dot-icon {
  font-size: $fs-14px !important;
  color: #FD4B9A !important;
  // fill: $dark-grey !important;
  margin-top: $fs-6px !important;
  margin-right: $fs-4px !important;
}

.media-card-position {
  min-height: $fs-48px;
  // padding: 0 $fs-10px;
}
.media-links {
  text-decoration: none;
  font-size: $fs-18px;
  // border-bottom: #EA215A 0.125em solid;
}

.text-underline {
  text-decoration: underline;
}

.media-section-title {
  font-family: $jostRegular;
  color: $blue;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-28px !important;
}

.dot-icon1 {
  font-size: $fs-14px !important;
  color: #00B8BD !important;
  // fill: $dark-grey !important;
  margin-top: $fs-6px !important;
  margin-right: $fs-4px !important;
}
.team-img-container{
  img {
    border-radius: 50%;
    object-fit: cover;
    width: 7rem;
    height: 7rem;
}
}


// .table-heading {
//   font: normal normal 600 25px/95px Nunito Sans;
// }
.column_design {
  background-color: lightgray;
  margin-bottom: 2% !important;
  border-radius: $fs-8px !important;
  font-size: $fs-16px;
  @include mobileScreen {
    background-color: lightgray;
  margin-bottom: 2% !important;
  border-radius: $fs-8px !important;
  font-size: $fs-16px;
  min-width: $fs-176px;
  }
}
.bg-white-smoke-light {
  background-color: $white-smoke-light;
}
.bg-white-smoke-medium{
  background-color: $white-smoke-medium;
}
.bg-white{
  background-color: #FFFFFF;
}
.bg-blue-medium{
  background-color: #F7F8FF;
}
.single_column_design {
  // border: 2px solid lightgray;
  padding: 0.65rem !important;
  line-height: 1 !important;
  font-size: $fs-16px;
  text-align: left;
  font: normal normal 300 16px/95px nunitoSans-Regular;
  letter-spacing: 0px;
  color: #292929;
  opacity: 1;
  // border-radius: $fs-4px !important;
}

.row-header-width {
  /* width: 37px; */
  // min-width: 40px !important;
  // font-size: 0.85rem;
  font-size: $fs-16px;
  text-align: left;
  font: normal normal 600 16px/16px nunitoSans-Regular;
  letter-spacing: 0px;
  color: #292929; 
  opacity: 1;
}
tr:nth-child(odd) {
  background-color: #F7F7F7 !important;
}

.column_design1 {
  background-color: #F7F8FF;
  margin-bottom: 1% !important;
  // border-radius: $fs-8px !important;
  min-width: $fs-352px;
  @include mobileScreen {
    background-color: #F7F8FF;
    margin-bottom: 1% !important;
    // border-radius: $fs-8px !important;
    min-width: $fs-176px;

  }
}

.popup-team-img-container {
  img {
    border-radius: 50%;
    object-fit: cover;
    width: 7rem;
    height: 7rem;
}
}

.custom-reference-links1 {
  font-family: $nunitoSans-Regular;
  color: #2069CE !important;
  @include setFont(normal, normal, normal, normal, center);
  line-height: $fs-8px;
  text-decoration: underline;
  font-size: $fs-14px !important;
  white-space: normal;
  word-break: break-all;
  &:hover {
    cursor: pointer;
  }
}