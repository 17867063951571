.map-container {
    height: 52vh;
    @include mobileScreen {
      height: 30vh;
    }
    @include desktop-max {
      height: 30vh;
    }
  }
  
  .contact-icon--width {
    width: $fs-16px;
  }
  
  .contact-icon-email---width {
    width: $fs-20px;
  }