.banner-container {
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  @include setPadding($fs-32px, $fs-8px, $fs-32px, $fs-8px);
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    width: 4rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobileScreen {
      width: 3rem;
    }
  }
  .title-container {
    padding-top: 2rem;
  }
  .dots-container {
    width: 30%;
    margin: auto;
    padding-bottom: 2rem;
    @include mobileScreen {
      width: 60%;
    }
    @include desktop-max-portrait {
      width: 50%;
    }
  }
  .lng-container {
    padding-top: 3rem;
    @include desktop-max {
      padding-top: 0;
    }
    @include mobileScreen {
      padding-top: 1rem;
    }
  }
  .title {
    @include desktop-max {
      font-size: $fs-32px !important;
    }
   
    @include desktop-max-portrait {
      font-size: $fs-32px !important;
    }
  }
}

.line-container {
  position: absolute;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  width: 100%;
}

.line-bt-container {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  width: 100%;
  &.bt-8px{
    bottom: -1rem;
  }
}

.line-bt-container1 {
  position: absolute;
  @include setMargin(260px, 0, 112px, 0);
  z-index: 999;
  left: 0;
  right: 0;
  width: 100%;
  &.bt-8px{
    bottom: -1rem;
  }
}

.container-video {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}