.AudioList {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    }

    .tableEvenRow{
        padding-top: 4pt;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        vertical-align:center;
        background-color:#c9daf8;
        width: 300pt;
        height:50pt;
        text-align: center;
    }        
    
    .tableOddRow{
        padding-top: 4pt;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        background-color: white;
        vertical-align:center;
        width: 300pt;
        height:50pt;
        text-align: center;
    }
    .headTable{
        font-size: 24px;
        text-align: center;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        background-color: white;
        vertical-align:center;
        width: 300pt;
        height:50pt;
    }
   

    