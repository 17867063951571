@import "./fonts";
@import "./screen-sizes";
@import "./mixins";
@import "./fonts";
@import "./typography";
@import "./common";
@import "./internal";
@import "./forms";
@import './button';
@import "./animate";
@import "./react-slick";
@import "../customComponents/customComponents";
@import "../customPages/customPages";
@import "./external";

.container {
  max-width: 80% !important;
  margin: auto;
  padding: 0 !important;
  @include mobileScreen {
    max-width: 90% !important;
    @include desktop {
      max-width: 85% !important;
    }
  }
}
