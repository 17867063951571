.header-container {
  position: fixed;
  width: 100%;
  top: 0;
  height: 3.5rem;
  background-color: var(--bs-white);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 6px #00000029;
  z-index: 999;
  justify-content: space-between;
  .menu-container {
    width: 95%;
    margin: auto;
    @include mobileScreen {
      width: 90%;
    }
    .width-25 {
      width: 25%;
    }
    .width-75 {
      width: 75%;
    }
    .header-title--container{
      position: absolute;
      top: 0;
      z-index: 999;
      height: 3.5rem;
    }
    .menu-center-position{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .custom-link--position {
      font-family: $jostRegular;
      font-size: $fs-14px;
      cursor: pointer;
      text-transform: capitalize;
      padding-bottom: 0.025rem;
      .custom-link {
        @include setPadding($fs-8px, $fs-22px, $fs-8px, $fs-22px);
        //color: $grey !important;
        cursor: pointer;
        &:hover {
          // color: $medium-pink !important;
          cursor: pointer;
        }
        &.custom-link-active {
          cursor: pointer;
         // font-weight: 600;
         // border-bottom: $fs-2px solid $transparent !important;
          // color: $medium-pink !important;
        }
      }
    }
  }
}

.menu-icon {
  width: 2rem;
}

.close-icon {
  width: 1.5rem;
}

.mobile-header {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  min-height: 48vh;
  background: $white;
  z-index: 999;
  padding-top: $fs-18px;
  padding-left: $fs-24px;
  padding-right: $fs-24px;
  box-shadow: 0px 0px 6px #00000029;
}

.custom-link--mobile-position {
  font-family: $jostRegular;
  font-size: $fs-18px;
  text-transform: capitalize;
  cursor: pointer;
  .custom-link {
    width: 100%;
    display: flex;
    @include setPadding($fs-16px, $fs-40px, $fs-16px, $fs-32px);
    cursor: pointer;
    &:hover {
      // color: $grey !important;
      cursor: pointer;
      //border-bottom: $fs-3px solid $medium-pink !important;
    }
    &.custom-link-active {
      cursor: pointer;
      // font-family: $jostRegular;
     // font-weight: 600;
      // border-bottom: $fs-3px solid $medium-pink !important;
      //color: $grey !important;
    }
  }
}

.header-title--mobile-position {
  position: absolute;
  left: 0;
  right: 0;
  width: 85%;
  padding-left: 4.5rem;
  top: 0;
  bottom: 0;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-icon {
  width: $fs-32px;
  height: $fs-22px;
  position: absolute;
  left: $fs-12px;
  top: $fs-8px;
}
.menu-icon {
  width: $fs-22px;
  height: $fs-14px;
  position: absolute;
  right: $fs-22px;
  top: $fs-18px;
  @include mobileScreen {
    width: $fs-30px;
    height: $fs-17px;
    top: $fs-22px;
  }
}
.mega-menu-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: transparent linear-gradient(249deg,#FFFFFF 0%, #FFFFFF 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  @include setPadding($fs-80px, $fs-2px, $fs-40px, $fs-2px);
  @include mobileScreen {
    overflow-y: scroll;
    @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px !important);
    position: fixed;
    width: 100%;
  left: 0;
  right: 0;
  top: 0;
    height: 100%;
  }
  &.mega-menu--show {
    opacity: 1 !important;
  }
  &.mega-menu--hide {
    position: absolute;
    top: -24rem;
    opacity: 0 !important;
    z-index: 999;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.mega-menu-logo {
  width: 2rem;
}

.mega-menu-link--position {
  font-family: $jostRegular;
  font-size: $fs-24px ;
  cursor: pointer;
  text-transform: capitalize;
  padding-bottom: 0.015rem;
  @include setPadding($fs-20px, $fs-2px, $fs-20px, $fs-16px);
  @include mobileScreen {
    font-size: $fs-15px;
  }
  .mega-menu-link {
    width: 100%;
    font-size: $fs-16px !important;
    display: flex;
    @include setPadding($fs-2px, $fs-2px, $fs-2px, $fs-2px);
    @include setMargin(0, 0, 10px, 0);
    width: 240px;
    color: #000000;
    cursor: pointer;
    @include mobileScreen {
      @include setPadding($fs-3px, 0, $fs-3px, 0);
    }
    &:hover {
      color: #00EBE9 !important;
      cursor: pointer;
    }
    &.mega-menu-link-active {
      cursor: pointer;
      font-family: $jostRegular;
      font-weight: 400;
      color: #000000 !important;
    }
  }
}
.mega-menu-title {
  font-family: $jostBold;
  color: #000000 !important;
  font-size: $fs-16px !important;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 10px, 0);
  @include setPadding(0, 0, 0, 0);
  @include mobileScreen {
    font-size: $fs-18px;
  }
}

.close-icon {
  width: 1.5rem;
  height: $fs-24px;
  position: absolute;
  right: $fs-20px;
  top: $fs-16px;
}
.logo-icon {
  width: $fs-80px;
  height: $fs-28px;
  position: absolute;
  left: $fs-12px;
  top: $fs-16px;
  @include mobileScreen {
    width: $fs-64px;
    height: $fs-22px;
    bottom: $fs-16px;
    left: $fs-14px;
    position: absolute;
  }
}

.syspin-logo {
  width: $fs-120px;
  position: relative;
  left: $fs-304px;
  // bottom: $fs-16px;
  @include mobileScreen {
    width: $fs-80px;
    height: $fs-40px;
    bottom: $fs-16px;
    left: $fs-88px;
    top: $fs-7px;
    position: absolute;
    @include setPadding($fs-2px, $fs-2px, $fs-2px, $fs-2px);
  }
}

.syspin-logo1 {
  width: $fs-120px;
  position: relative;
  left: $fs-600px;
  bottom: $fs-75px;
  @include setPadding($fs-2px, $fs-2px, $fs-2px, $fs-2px);
  @include mobileScreen {
    width: $fs-10px;
  height: $fs-10px;
  position: absolute;
  left: $fs-2px;
  top: $fs-2px;
  }
}

