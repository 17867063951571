@import "fonts";
@import "colors";
@import "mixins";
@import "screen-sizes";

.p-very-small {
  font-size: $fs-11px !important;
  line-height: calc(0.65rem * 1.2) !important;
}
.p-lesserthan-small {
  font-size: $fs-12px !important;
  line-height: calc(0.65rem * 1.2) !important;
}
.p-small {
  font-size: $fs-14px !important;
  line-height: calc(0.75rem * 1.2) !important;
}
.p-small-terms-condtion {
  font-size: $fs-14px !important;
  line-height: calc(0.8rem * 1.2) !important;
}
p {
  font-size: $fs-14px !important;
  line-height: calc(0.95rem * 1.2) !important;
}
.p-medium {
  font-size: $fs-15px !important;
  line-height: calc(0.95rem * 1.2) !important;
}
.p-medium-terms-condition {
  font-size: $fs-16px !important;
  line-height: calc(0.95rem * 1.2) !important;
}
.p-medium-line-spacing {
  font-size: $fs-16px !important;
  line-height: calc(1.125rem * 1.2) !important;
}


.p-large {
  font-size: $fs-16px !important;
  line-height: calc(1.75rem * 1.2) !important;
}
.p-medium{
  font-size: $fs-20px !important;
  line-height: calc(0.95rem * 1.2) !important;
}
.h6-large {
  font-size: $fs-14px !important;
  line-height: calc(1.4rem * 1.14) !important;
}
.h6-small {
  font-size: $fs-18px !important;
  line-height: calc(1.4rem * 1.14) !important;
}
.h6-medium {
  font-size: $fs-20px !important;
  line-height: calc(1.4rem * 1.14) !important;
}

.h5-small {
  font-size: $fs-22px !important;
  line-height: calc(1.4rem * 1.14) !important;
}

.h5-medium {
  font-size: $fs-24px !important;
  line-height: calc(1.6rem * 1.14) !important;
}
.h4-small{
  font-size: $fs-25px !important;
  line-height: calc(2.125rem * 1.14) !important;
}
.h4-medium {
  font-size: $fs-28px !important;
  line-height: calc(2.125rem * 1.14) !important;
}

.h3-large {
  font-size: $fs-30px !important;
  line-height: calc(2.35rem * 1.14) !important;
}

.h2-small {
  font-size: $fs-32px !important;
  line-height: calc(2.45rem * 1.14) !important;
}
.title-h2-small {
  font-size: $fs-24px !important;
  line-height: calc(1.45rem * 1.14) !important;
}
.h3-small {
  font-size: $fs-25px !important;
  line-height: calc(1.125rem * 1.14) !important;
}

.h1-small {
  font-size: $fs-30px !important;
  line-height: calc(3.5rem * 1.14) !important;
}
.h1-large {
  font-size: $fs-42px !important;
  line-height: calc(3.5rem * 1.14) !important;
}
